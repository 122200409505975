import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import { requiredLabelPropCheck, INPUT_SIZES } from 'helpers/forms';
import InputLabel from '../InputLabel';
import BaseInput from '../BaseInput';
import InputError from '../InputError';
import styles from './TextInputStyles';

const useStyles = makeStyles({ name: 'TextInput' })(styles);

/*
  Must Be Wrapped Inside a Form Element
*/
const TextInput = (props) => {
  const {
    className,
    inputClass,
    error,
    errorMessage,
    label,
    name,
    id,
    disabled,
    size,
    description,
    isRequired,
    ...otherProps
  } = props;
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      {label && (
        <InputLabel
          htmlFor={id}
          size={size}
          disabled={disabled}
          description={description}
          isRequired={isRequired}
        >
          {label}
        </InputLabel>
      )}
      <BaseInput
        className={inputClass}
        disabled={disabled}
        error={error}
        id={id}
        name={name}
        size={size}
        {...otherProps}
      />
      {(error && errorMessage) && (
        <InputError
          inputId={id}
          error={error}
          errorMessage={errorMessage}
        />
      )}
    </div>
  );
};

TextInput.propTypes = {
  'aria-label': requiredLabelPropCheck,
  label: requiredLabelPropCheck,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.node,
  ]),
  inputClass: PropTypes.string,
  isRequired: PropTypes.bool,
  size: PropTypes.oneOf(INPUT_SIZES),
};

TextInput.defaultProps = {
  'aria-label': undefined,
  className: undefined,
  description: undefined,
  disabled: false,
  error: undefined,
  errorMessage: undefined,
  isRequired: false,
  label: undefined,
  inputClass: undefined,
  size: 'medium',
};

export default TextInput;
